/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState, useEffect } from 'react';
import { Box, Image, Text } from '@theme-ui/components';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import FooterSection from '../components/03_organisms/FooterSection/FooterSection';
import GenericSection from '../components/03_organisms/GenericSection/GenericSection';
import HeaderSection from '../components/03_organisms/HeaderSection/HeaderSection';
import JourneyItem from '../components/02_molecules/JourneyItem/JourneyItem';
import Section from '../components/02_molecules/Section/Section';
import Layout from '../global/Layout';
import Seo from '../global/Seo';
import AppStoreBadgePath from '../assets/AppStoreBadge.de.svg';

const elementIdSignup = 'cta';

const IndexPage: React.FC = () => {
  const [isClient, setClient] = useState(false);
  // re-render component in client because hydration does not work correctly on production builds
  useEffect(() => {
    setClient(true);
  }, []);
  const key = isClient ? 'journeySection-client' : 'journeySection-server';

  const data = useStaticQuery(graphql`
    query {
      descriptionImage: file(
        relativePath: { eq: "assets/description.de.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      categoryImage: file(relativePath: { eq: "assets/category.de.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      favoritesImage: file(relativePath: { eq: "assets/favorites.de.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      darkmodeImage: file(relativePath: { eq: "assets/darkmode.de.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo
        lang="de"
        title="Deine App für Beschäftigung Zuhause"
        titleTemplate=""
        description="Langeweile Zuhause? Spiele für den Kindergeburtstag? Spielideen ist Deine App mit kreativen Vorschlägen für Spiele in allen Situationen für die Du kein Smartphone benötigst."
      />
      <Helmet>
        <link rel="alternate" hreflang="en" href="https://apps.patrickpuritscher.com/gameideas/en/" />
      </Helmet>
      <Box>
        <HeaderSection
          // fullViewportHeight
          appStoreBadgePath={AppStoreBadgePath}
          backgroundColor="white"
          title="Spielideen? Lass Dich inspirieren."
          subtitle="Langeweile Zuhause? Spiele für den Kindergeburtstag? Spielideen ist Deine App mit kreativen Vorschlägen für Spiele in allen Situationen für die Du kein Smartphone benötigst."
        />

        <Section
          py={3}
          contentSx={{
            alignItems: 'center',
            maxWidth: 5,
          }}>
          <Box
            key={key}
            sx={{
              my: [0, 20],
            }}>
            <JourneyItem
              alignRight
              title="Hol Dir Spielideen"
              description="Nicht nur längst vergessene Klassiker wie Ich sehe was, was Du nicht siehst!, sondern auch unbekanntere Spiele, wie Rote Socken, blaue Schuhe. Spiele am Handy kann jeder – versuche es mal wieder ohne Smartphone! :)"
              buttonLabel="Hört sich gut an?"
              buttonHref={`#${elementIdSignup}`}
              imageFluid={data?.categoryImage?.childImageSharp?.fluid}
            />
            <JourneyItem
              title="5 Sterne im App Store"
              description="Großartige Idee, tolle App; Viele tolle Spiele; Eine Zeitreise in meine Kindheit;.. schließe Dich unseren glücklichen Nutzern an."
              buttonLabel="Sei dabei!"
              buttonHref={`#${elementIdSignup}`}
              imageFluid={data?.favoritesImage?.childImageSharp?.fluid}
            />
            <JourneyItem
              alignRight
              title="Du hast eine Idee?"
              description="Wenn Du eine Idee hast wie wir die App noch besser machen können oder Du kennst ein Spiel, welches noch nicht enthalten ist – lass es uns wissen! Wir freuen uns über Deine Rückmeldung."
              imageFluid={data?.descriptionImage?.childImageSharp?.fluid}
              buttonHref={`#${elementIdSignup}`}
              buttonLabel="Schau es Dir an!"
            />
            <JourneyItem
              title="Spielideen Premium"
              description="Die Entwicklung der App kostet uns viel Zeit, wie auch die Recherche und Redaktion der Spiele. Wir freuen uns sehr darüber, wenn Du uns mit dem Kauf von Spielideen Premium unterstützt. Das kostet weniger als eine Tasse Kaffee, bringt uns allerdings riesen Freude und noch mehr Ansporn! :)"
              imageFluid={data?.darkmodeImage?.childImageSharp?.fluid}
              buttonHref={`#${elementIdSignup}`}
              buttonLabel="Probiere Spielideen aus!"
            />
          </Box>
        </Section>

        <div id={elementIdSignup} />
        <GenericSection
          additionalVerticalSpacing
          // backgroundColor="lightprimary"
          title="Lass Dich inspirieren. Jetzt."
          subtitle="Spielideen ist kostenlos im App Store verfügbar mit Vorschlägen zum Zeitvertreib für Deinen nächsten Kindergeburtstag, Ausflug und vieles mehr.">
          <a href="https://itunes.apple.com/de/app/keynote/id1450078546?mt=8">
            <Image
              src={AppStoreBadgePath}
              sx={{
                mt: 5,
                width: '13rem',
              }}
            />
          </a>
        </GenericSection>
        {/* <GenericSection additionalVerticalSpacing title="Du hast eine Idee?">
          <Flex
            sx={{
              mt: 4,
              justifyContent: 'center',
              width: '100%',
            }}> */}
        {/* hex encoded mailto link to prevent harvesting crawlers */}
        {/* <a href="&#x6d;&#x61;&#x69;&#x6c;&#x74;&#x6f;&#x3a;&#x61;&#x70;&#x70;&#x73;&#x40;&#x70;&#x61;&#x74;&#x72;&#x69;&#x63;&#x6b;&#x70;&#x75;&#x72;&#x69;&#x74;&#x73;&#x63;&#x68;&#x65;&#x72;&#x2e;&#x63;&#x6f;&#x6d;">
              <Button
                sx={{
                  bg: 'bluegrey',
                }}>
                Schreibe uns :)
              </Button>
            </a>
          </Flex>
        </GenericSection> */}

        <Section
          sx={{ pt: 7, pb: 5 }}
          contentSx={{
            maxWidth: 7,
            alignItems: 'center',
          }}>
          <Text variant="small" mb={5}>
            Apple and the Apple logo are trademarks of Apple Inc., registered in
            the U.S. and other countries and regions. App Store is a service
            mark of Apple Inc.
          </Text>
        </Section>
        <FooterSection
          links={[
            {
              label: 'Hinweise zum Datenschutz',
              href: '/privacy-policy/',
            },
            {
              label: 'Impressum',
              href: '/imprint/',
            },
          ]}
        />
      </Box>
    </Layout>
  );
};

export default IndexPage;
