import React from 'react';
import { Box, Flex } from '@theme-ui/components';
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';

/** @jsx jsx */

export interface FooterProps {
  links?: {
    href: string;
    label: string;
  }[];
}
const Footer: React.FC<FooterProps> = ({ links = [] }: FooterProps) => {
  let linksWithoutLast: FooterProps['links'] = [];
  if (links.length > 1) {
    linksWithoutLast = links.slice(0, -1);
  }
  let lastLink = null;
  if (links.length > 0) {
    lastLink = links[links.length - 1];
  }

  return (
    <Box
      sx={{
        color: 'bluegrey',
      }}>
      {linksWithoutLast.map((link) => (
        <span key={`linkto${link.href}`}>
          <Link
            to={link.href}
            sx={{
              variant: 'links.footer',
            }}>
            {link.label}
          </Link>{' '}
          |{' '}
        </span>
      ))}
      {!!lastLink && (
        <span>
          <Link
            to={lastLink.href}
            sx={{
              variant: 'links.footer',
            }}>
            {lastLink.label}
          </Link>
        </span>
      )}
    </Box>
  );
};

export default Footer;
