import React from 'react';
import { Box, Text, Grid, Flex, Button, Image } from '@theme-ui/components';
import Img, { FluidObject } from 'gatsby-image';
import { useResponsiveValue } from '@theme-ui/match-media';

export interface JourneyItemProps {
  title?: string;
  description?: string;
  imageFluid?: FluidObject;
  imagePath?: string;
  alignRight?: boolean;
  buttonLabel?: string;
  buttonHref?: string;
}

const JourneyItem: React.FC<JourneyItemProps> = ({
  title,
  description,
  imageFluid,
  imagePath,
  alignRight,
  buttonLabel,
  buttonHref,
}: JourneyItemProps) => {
  const order = useResponsiveValue([0, alignRight ? 0 : 1]);

  return (
    <Box mb={[10, 10, 18]}>
      <Grid columns={[1, 2]} gap={[8, 15]}>
        <Flex
          sx={{
            order,
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          <Box
            sx={{
              textAlign: ['center', 'left'],
            }}>
            <Text
              sx={{
                variant: 'text.default',
                fontSize: [4, 4],
                fontWeight: 'bold',
                mb: 4,
              }}>
              {title}
            </Text>
            <Text
              sx={{
                variant: 'text.default',
                fontSize: [1, 1],
                mb: 4,
              }}>
              {description}
            </Text>
            {/* <a href={buttonHref}>
              <Button>{buttonLabel}</Button>
            </a> */}
          </Box>
        </Flex>
        <Box>
          {imageFluid && (
            <Box
              sx={{
                maxWidth: ['14rem', '17rem'],
                margin: '0 auto',
              }}>
              <Img
                fluid={imageFluid}
                alt={title}
                style={{
                  maxWidth: '100%',
                  margin: '0 auto',
                  height: 'auto',
                  width: '100%',
                }}
              />
            </Box>
          )}
          {imagePath && (
            <Image
              src={imagePath}
              alt={title}
              sx={{
                maxWidth: ['14rem', '17rem'],
                margin: '0 auto',
                height: 'auto',
                width: '100%',
              }}
            />
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default JourneyItem;
