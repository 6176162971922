import React from 'react';
import { Link } from '@theme-ui/components';

const id = 'skip-nav';

export interface SkipNavLink {
  label?: string;
}
export const SkipNavLink: React.FC<SkipNavLink> = ({
  label = 'Skip to content',
}: SkipNavLink) => {
  return (
    <Link href={`#${id}`} variant="skipnav" sx={{}}>
      {label}
    </Link>
  );
};

export const SkipNavContent: React.FC = () => <div id={id} />;
