import React from 'react';
import { FluidObject } from 'gatsby-image';
import { Image, Box, IconButton } from '@theme-ui/components';
import Logo from '../../01_atoms/Logo/Logo';
import { SkipNavContent, SkipNavLink } from '../../01_atoms/SkipNav/SkipNav';
import Section from '../../02_molecules/Section/Section';
import Text from '../../01_atoms/Text/Text';

export interface HeaderSectionProps {
  title?: string;
  subtitle?: string;
  backgroundColor?: string;
  backgroundImageFluid?: FluidObject;
  backgroundVideoSrc?: string;
  fullViewportHeight?: boolean;
  renderSocialLinks?: boolean;
  appStoreBadgePath?: string;
}

const HeaderSection: React.FC<HeaderSectionProps> = ({
  title,
  subtitle,
  backgroundColor,
  backgroundVideoSrc,
  fullViewportHeight,
  appStoreBadgePath,
}: HeaderSectionProps) => {
  return (
    <>
      <SkipNavLink />
      <Box
        as="section"
        sx={{
          position: 'relative',
        }}>
        <Section
          py={0}
          as="div"
          backgroundMaxWidth="100vw"
          backgroundColor={backgroundColor}
          // backgroundImageFluid={backgroundImage?.childImageSharp?.fluid}
          backgroundVideoSrc={backgroundVideoSrc}
          contentSx={{
            height: fullViewportHeight ? '100vh' : 'auto',
            maxWidth: 7,
            justifyContent: 'space-between',
            mb: 5,
          }}>
          <Logo
            sx={{
              height: ['5rem'],
              mt: 8,
              mb: 10,
            }}
          />
          <Box
            sx={{
              flex: 1,
            }}>
            <Text
              variant="h1"
              color="black"
              sx={{
                fontWeight: 'bold',
                mb: 5,
                textAlign: 'center',
              }}>
              {title}
            </Text>

            <Box
              sx={{
                maxWidth: 3,
                m: '0 auto',
                textAlign: 'center',
              }}>
              <Text variant="section.subtitle" color="black">
                {subtitle}
              </Text>
              {!!appStoreBadgePath && (
                <a href="https://itunes.apple.com/de/app/keynote/id1450078546?mt=8">
                  <Image
                    src={appStoreBadgePath}
                    sx={{
                      mt: 12,
                      mb: [12, 0],
                      width: '12rem',
                    }}
                  />
                </a>
              )}
            </Box>
          </Box>
        </Section>
        {fullViewportHeight && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '0',
              width: '100%',
              textAlign: 'center',
              pt: 6,
            }}>
            <IconButton
              aria-hidden="true"
              sx={{
                pb: 0,
                height: ['15vw', '15vw', '15vw', '15vw', '8vw'],
                width: 'auto',
                flexDirection: 'column',
              }}>
              <Box
                sx={{
                  flex: '1 1 0',
                }}
              />
            </IconButton>
          </Box>
        )}
      </Box>
      <SkipNavContent />
    </>
  );
};

export default HeaderSection;
