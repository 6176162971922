import React from 'react';
import { Box } from '@theme-ui/components';
import Section from '../../02_molecules/Section/Section';
import Footer, { FooterProps } from '../../02_molecules/Footer/Footer';

export interface FooterSectionProps {
  links?: FooterProps['links'];
}
const FooterSection: React.FC<FooterSectionProps> = ({
  links,
}: FooterSectionProps) => (
  <>
    <Section
      sx={{ pt: 7, pb: 5 }}
      contentSx={{
        maxWidth: 7,
        alignItems: 'center',
      }}>
      <Footer links={links} />
      <Box
        sx={{
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
      />
    </Section>
  </>
);

export default FooterSection;
